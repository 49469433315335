<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="类型:" label-width="47px" class="w-170">
					<el-select v-model="table.where.type" clearable>
						<el-option value="0" label="全部"></el-option>
						<!-- <el-option value="4" label="跑腿"></el-option> -->
						<el-option value="1" label="超市"></el-option>
						<el-option value="2" label="叫餐"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="姓名:" label-width="50px" class="w-200">
					<el-input v-model="table.where.name" placeholder="" clearable />
				</el-form-item>
				<el-form-item label="手机号:" label-width="62px" class="w-200">
					<el-input v-model="table.where.phone" placeholder="" clearable />
				</el-form-item>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
						placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
					<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="driver/exportExcel" v-if="permission.includes('sys:driver:export')"
						:params="exportOrderParams()" />
				</el-form-item>
			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="type_name" label="类型" show-overflow-tooltip min-width="80">
						<!-- <template slot-scope="scope">
							{{scope.row.type==1?'司机':(scope.row.type==2?'商铺':(scope.row.type==3?'超市':
							(scope.row.type==4?'跑腿':(scope.row.type==5?'叫餐':(scope.row.type==6?'生活':'')))))}}
						</template> -->
					</el-table-column>
					<el-table-column prop="name" label="店铺名称" show-overflow-tooltip min-width="140">
						<!-- <template slot-scope="scope">
								{{scope.row.type==3?scope.row.store_name:(scope.row.type==4?scope.row.company_name:(scope.row.type==5?scope.row.store_name:''))}}
							</template> -->
						</el-table-column>
						<el-table-column prop="check_info.true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
					<!-- <el-table-column prop="sex" label="性别" show-overflow-tooltip min-width="60">
						<template slot-scope="scope">
							{{scope.row.sex==0?'男':(scope.row.sex==1?'女':'男')}}
						</template>
					</el-table-column> -->
					<el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="province_id" label="所属地区" show-overflow-tooltip min-width="220" >
						<template slot-scope="scope">
								{{scope.row.province_name}} {{scope.row.city_name}} {{scope.row.area_name}}
							</template>
					</el-table-column>
					<el-table-column prop="service_charge" label="服务费收取比例（%）" show-overflow-tooltip min-width="160" />
					<el-table-column prop="money" label="累计接单收益(元)" show-overflow-tooltip min-width="140" />
					<el-table-column prop="order_total" label="累计接单量" show-overflow-tooltip min-width="100" />
					<el-table-column prop="invite_money" label="累计推广收益(元)" show-overflow-tooltip min-width="140" />
					<el-table-column prop="invite_num" label="累计推广量" show-overflow-tooltip min-width="100" />
					<el-table-column prop="credit" label="评分" show-overflow-tooltip min-width="50" />
					<el-table-column prop="total_time" label="累计在线时长(分钟)" show-overflow-tooltip min-width="145" />
					<el-table-column label="最近登录时间" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.active_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="online_order_nums" label="进行中订单（笔）" show-overflow-tooltip
						min-width="180" />
					<el-table-column label="注册时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time }}</template>
					</el-table-column>
					<el-table-column label="操作" width="260px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false" v-if="permission.includes('sys:merchant:view')">查看详情</el-link>
              <el-link @click="handleEdit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:merchant:edit')">编辑资料</el-link>
							<el-dropdown @command="command => dropClick(command,row)">
								<span class="el-dropdown-link" style="margin-left: 10px;">
									<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
								</span>
								<el-dropdown-menu slot="dropdown">
									<!--置为开工 置为营业 -->
									<!-- <el-dropdown-item icon="el-icon-s-data" v-if="permission.includes('sys:driver:out_list')" command="out_list">置为开工</el-dropdown-item> -->
                  <el-dropdown-item icon="el-icon-s-check" command="check_list" v-if="permission.includes('sys:merchant:check_list')">营业记录</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-error" v-if="permission.includes('sys:merchant:forlimit')" command="forlimit">限号</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-error" command="blackData" v-if="permission.includes('sys:merchant:addblack')">加入黑名单</el-dropdown-item>
<!--                  <el-dropdown-item icon="el-icon-error" command="restrictedWithdrawal">限制提现</el-dropdown-item>
-->									<!-- <el-dropdown-item icon="el-icon-s-data" command="out_list">置为开工</el-dropdown-item>
									<el-dropdown-item icon="el-icon-s-check" command="check_list">开工记录</el-dropdown-item>
									<el-dropdown-item icon="el-icon-error" command="forlimit">限号</el-dropdown-item>
									<el-dropdown-item icon="el-icon-error" command="blackData">加入黑名单</el-dropdown-item> -->
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看商家详情 -->
		<el-dialog v-dialogDrag title="查看商家详情" :visible.sync="dialogTableVisibleCheck" width="850px" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="200px">
					<div style="display: flex;">
						<div style="padding-right: 50px; border-right: 1px solid #C6C6C6;">
							<el-form-item label="今日取消订单量:" prop="money">
								{{checkForm.today_cancel}}
							</el-form-item>
							<el-form-item label="本月累计取消订单量:" prop="invite_money">
								{{checkForm.thisMon_cancel}}
							</el-form-item>
							<el-form-item label="本月累计接单量：" prop="total_time">
								{{checkForm.month_order_total}}
							</el-form-item>
							<el-form-item label="本月总收益：" prop="money">
								{{checkForm.month_order_price}}
							</el-form-item>
							<el-form-item label="本月订单收益：" prop="invite_money">
								{{checkForm.month_order_earning}}
							</el-form-item>
							<el-form-item label="本月红包收益：" prop="total_time">
								{{checkForm.month_order_fee}}
							</el-form-item>
							<el-form-item label="本月推广收益：" prop="total_time">
								{{checkForm.month_order_promote}}
							</el-form-item>
							<el-form-item label="商家姓名：" prop="true_name">
								{{checkForm.name}}
							</el-form-item>
							<el-form-item label="头像：">
								<el-image v-if="checkForm.true_head" style="width:70px;height:70px;" :src="checkForm.true_head"
									fit="cover" :preview-src-list="[checkForm.true_head]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<!-- <el-form-item label="是否载客" prop="phone">
								{{checkForm.is_carry}}
							</el-form-item> -->
							<el-form-item label="手机号：" prop="phone">
								{{checkForm.phone}}
							</el-form-item>
							<el-form-item label="所属区域：">
								{{checkForm.province_name}} {{checkForm.city_name}} {{checkForm.area_name}}
							</el-form-item>
							<el-form-item label="累计接单收益：" prop="money">
								{{checkForm.money}}
							</el-form-item>
							<el-form-item label="诚信度：" prop="invite_money">
								{{checkForm.credit}}
							</el-form-item>
							<el-form-item label="累计在线时长：" prop="total_time">
								{{checkForm.total_time}} 分钟
							</el-form-item>
							<el-form-item label="最近的登录时间：" prop="money">
								{{checkForm.active_time*1000 | toDateString }}
							</el-form-item>
						</div>
						<div>
							<el-form-item label="注册时间：" prop="invite_money">
								{{checkForm.create_time }}
							</el-form-item>
							<el-form-item label="真实姓名：" prop="check_info.true_name">
								{{checkForm.check_info.true_name}}
							</el-form-item>
							<el-form-item label="身份证号：" prop="check_info.idcard">
								{{checkForm.check_info.idcard}}
							</el-form-item>
							<el-form-item label="支付宝账号：" prop="idcard">
								{{checkForm.ali_number?checkForm.ali_number:'无'}}
							</el-form-item>
							<!-- <el-form-item label="车牌号：" prop="car_number" v-if="checkForm.type == 4">
								{{checkForm.car_number}}
							</el-form-item> -->
							<el-form-item label="手持身份证照片：">
								<el-image v-if="checkForm.check_info.idcard_hand" style="width:70px;height:70px;" :src="checkForm.check_info.idcard_hand"
									fit="cover" :preview-src-list="[checkForm.check_info.idcard_hand]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="身份证正面照：">
								<el-image v-if="checkForm.check_info.idcard_front" style="width:70px;height:70px;" :src="checkForm.check_info.idcard_front"
									fit="cover" :preview-src-list="[checkForm.check_info.idcard_front]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="身份证反面照：">
								<el-image v-if="checkForm.check_info.idcard_back" style="width:70px;height:70px;" :src="checkForm.check_info.idcard_back"
									fit="cover" :preview-src-list="[checkForm.check_info.idcard_back]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<!-- <el-form-item label="驾驶证正面照：" v-if="checkForm.type == 4">
								<el-image style="width:70px;height:70px;" :src="checkForm.jiacard_front"
									fit="cover" :preview-src-list="[checkForm.jiacard_front]"></el-image>
							</el-form-item> -->
							<el-form-item label="营业执照：">
								<el-image v-if="checkForm.check_info.business_license" style="width:70px;height:70px;" :src="checkForm.check_info.business_license"
									fit="cover" :preview-src-list="[checkForm.check_info.business_license]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="店铺logo：">
								<el-image v-if="checkForm.check_info.shop_logo" style="width:70px;height:70px;" :src="checkForm.check_info.shop_logo"
									fit="cover" :preview-src-list="[checkForm.check_info.shop_logo]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="店铺门面：">
								<el-image v-if="checkForm.check_info.shop_doorheader " style="width:70px;height:70px;" :src="checkForm.check_info.shop_doorheader "
									fit="cover" :preview-src-list="[checkForm.check_info.shop_doorheader ]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="食品安全许可证：" v-if="checkForm.type == 5">
								<el-image v-if="checkForm.food_safety" style="width:70px;height:70px;" :src="checkForm.food_safety"
									fit="cover" :preview-src-list="[checkForm.food_safety]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="公司法人身份证正面：" v-if="checkForm.type == 4">
								<el-image v-if="checkForm.company_legal_idcard_font" style="width:70px;height:70px;" :src="checkForm.company_legal_idcard_font"
									fit="cover" :preview-src-list="[checkForm.company_legal_idcard_font]"></el-image>
								<span v-else>无</span>
							</el-form-item>
							<el-form-item label="公司法人身份证反面：" v-if="checkForm.type == 4">
								<el-image v-if="checkForm.company_legal_idcard_back" style="width:70px;height:70px;" :src="checkForm.company_legal_idcard_back"
									fit="cover" :preview-src-list="[checkForm.company_legal_idcard_back]"></el-image>
								<span v-else>无</span>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 编辑商家 -->
		<el-dialog v-dialogDrag title="编辑商家" :visible.sync="dialogTableVisibleEdit" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="170px">
					<el-form-item label="真实姓名：" prop="name">
						<el-input v-model="editForm.name" placeholder="请输入" style="width:160px" clearable />
					</el-form-item>
					<el-form-item label="联系方式：" prop="phone">
						<el-input v-model="editForm.phone" placeholder="请输入" style="width:160px" clearable />
					</el-form-item>
					<div>
						<el-form-item label="所属区域：" prop="pid">
							<el-select v-model="editForm.pname" @change="handleChangeProv1(editForm.pname)"
								class="selectStyle mr-10 mb-20" placeholder="请选择省" style="width:160px" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
								class="selectStyle mr-10 mb-20" placeholder="请选择市" style="width:160px" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
								class="selectStyle mr-10 mb-20" placeholder="请选择区/县" style="width:160px" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<el-form-item label="类型:" prop="type">
						<el-select v-model="editForm.type" placeholder="请选择类型" class="input163" clearable>
							<!-- <el-option label="司机" :value="1"></el-option>
							<el-option label="商铺" :value="2"></el-option> -->
							<el-option label="超市" :value="3"></el-option>
							<el-option label="跑腿" :value="4"></el-option>
							<el-option label="叫餐" :value="5"></el-option>
							<!-- <el-option label="生活" :value="6"></el-option> -->
						</el-select>
					</el-form-item>
					<el-form-item label="累计接单收益：" prop="money">
						{{editForm.money}}
					</el-form-item>
					<el-form-item label="推广总收益：" prop="invite_money">
						{{editForm.invite_money}}
					</el-form-item>
					<el-form-item label="累计在线时长：" prop="total_time">
						{{editForm.total_time}} 分钟
					</el-form-item>
					<el-form-item label="诚信度：" prop="invite_money">
						{{editForm.credit}}
					</el-form-item>
					<el-form-item label="性别：" prop="sex">
						{{editForm.sex}}
					</el-form-item>
					<el-form-item label="身份证号：" prop="idcard">
						{{editForm.idcard}}
					</el-form-item>
					<!-- <el-form-item label="车牌号：" prop="car_number" v-if="checkForm.type == 4">
						{{editForm.car_number}}
					</el-form-item> -->
					<!-- <el-form-item label="车牌数量：" prop="car_persons" v-if="checkForm.type == 4">
						{{editForm.car_persons}}
					</el-form-item> -->
					<el-form-item label="真实头像：">
						<uploadImage :limit="1" v-model="editForm.true_head"></uploadImage>
					</el-form-item>
					<el-form-item label="手持身份证照片：">
						<uploadImage :limit="1" v-model="editForm.idcard_hand"></uploadImage>
					</el-form-item>
					<el-form-item label="身份证正面照：">
						<uploadImage :limit="1" v-model="editForm.idcard_front"></uploadImage>
					</el-form-item>
					<el-form-item label="身份证反面照：">
						<uploadImage :limit="1" v-model="editForm.idcard_back"></uploadImage>
					</el-form-item>
					<!-- <el-form-item label="驾驶证正面照：" v-if="editForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.jiacard_front"></uploadImage>
					</el-form-item>
					<el-form-item label="行驶证正面照：" v-if="editForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.xingcard_front"></uploadImage>
					</el-form-item>
					<el-form-item label="车辆正面照：" v-if="editForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.car_front"></uploadImage>
					</el-form-item>
					<el-form-item label="车辆侧面照：" v-if="editForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.car_profile"></uploadImage>
					</el-form-item>
					<el-form-item label="出租车运营资格证：" v-if="editForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.taxi_operation"></uploadImage>
					</el-form-item> -->
					<el-form-item label="营业执照：" v-if="checkForm.type == 3 || checkForm.type == 5">
						<uploadImage :limit="1" v-model="editForm.business_license"></uploadImage>
					</el-form-item>
					<el-form-item label="食品安全许可证：" v-if="checkForm.type == 5">
						<uploadImage :limit="1" v-model="editForm.food_safety"></uploadImage>
					</el-form-item>
					<el-form-item label="公司法人身份证正面：" v-if="checkForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.company_legal_idcard_font"></uploadImage>
					</el-form-item>
					<el-form-item label="公司法人身份证反面：" v-if="checkForm.type == 4">
						<uploadImage :limit="1" v-model="editForm.company_legal_idcard_back"></uploadImage>
					</el-form-item>
					
					<el-form-item label="注册时间：" prop="create_time">
						{{ editForm.create_time*1000 | toDateString }}
					</el-form-item>
					<el-form-item label="最近活跃时间：" prop="active_time">
						{{ editForm.active_time*1000 | toDateString }}
					</el-form-item>
					
							<!-- <span>{{scope.row.type==3?'超市':(scope.row.type==4?'跑腿':'叫餐')}}</span> -->
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="dialogTableVisibleEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>

		<!-- 营业记录 -->
		<el-dialog v-dialogDrag title="营业记录" :visible.sync="dialogTableVisibleRecords" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="table1.where" label-width="77px" class="ele-form-search mb-10 d-flex"
					style="float:right">
					<el-form-item label="开始时间:">
						<el-date-picker style="width:150px" v-model="table1.where.strat_time" type="date"
							placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="结束时间:">
						<el-date-picker style="width:150px" v-model="table1.where.end_time" type="date"
							placeholder="选择日期">
						</el-date-picker>
						<el-button type="primary" @click.native="searchRecords()" icon="el-icon-search"
							class="ele-btn-icon  ml-20">搜索</el-button>
					</el-form-item>
				</el-form>
				<el-table :data="recordsList" element-loading-text="列表正在加载中" border fit highlight-current-row
					style="width: 100%;">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
					<el-table-column prop="name" label="店铺名称" show-overflow-tooltip min-width="120" />
					<el-table-column prop="start_time" label="营业时间" show-overflow-tooltip min-width="120" >
						<template slot-scope="{row}">
							{{ row.start_time*1000 | toDateString }}
						</template>
					</el-table-column>
					<el-table-column prop="end_time" label="打烊时间" show-overflow-tooltip min-width="120" >
						<template slot-scope="{row}">
							{{ row.end_time*1000 | toDateString }}
						</template>
					</el-table-column>
					<!-- <el-table-column prop="create_time" label="出/收车检测时间" show-overflow-tooltip min-width="160" />
					<el-table-column prop="face_img" label="检测上传图片" show-overflow-tooltip min-width="120" /> -->
				</el-table>
				<el-pagination v-if="recordsShow" @current-change="handleCurrentChange" :current-page.sync="pageNumber"
					layout="total, prev, pager, next, jumper" :page-sizes="[5, 10, 15, 20]" :page-size="10"
					:total="recordsTotal">
				</el-pagination>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import uploadImage from '@/components/uploadImage'
	import download from '@/components/Download/index'
	export default {
		name: "SysUser",
		components: {
			uploadImage,
			download
		},
		data() {
			return {
				table: {
					url: '/store/listStore',
					// url: '/driver/index',
					where: {
						m_type: 2
					}
				}, // 表格配置
				table1: {
					url: '/driver/driver_check_log',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: { // 表单验证规则
					name: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					phone: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}],
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}]
				},
				provArr: [],
				cityArr: [],
				districtArr: [],
				checkForm: {
					check_info:{}
				},
				recordsList: [],
				startTime: '',
				endTime: '',
				driverData: {},
				dialogTableVisibleEdit: false,
				dialogTableVisibleCheck: false,
				dialogTableVisibleRecords: false,
				pageSize: 10,
				pageNumber: 1,
				recordsTotal: 1000,
				recordsShow: false,
				recordsId: '',
				loading: true,
				pid: '',
				cid: '',
				aid: '',
				changePro: false,
			}
		},
		created() {


			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})

		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				// const params = this.params
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			dropClick(command, row) {
				if (command === 'edit') { // 查看用户信息
					this.edit()
				} else if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'out_list') { //出车记录
					this.handleCarRecords(row)
				} else if (command === 'check_list') { //营业记录
					this.handleRecords(row)
				} else if (command === 'edit') { //修改用户信息
					this.edit(row)
				} else if (command === 'forlimit') { //限号
					this.forlimit(row)

				}
				if (command === 'blackData') { //拉入黑名单
					this.blackData(row)

				}
				if(command === "restrictedWithdrawal"){//限制体现
					this.restrictedWithdrawal(row);
				}
			},

			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},


			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				let that = this
				this.changePro = false
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					that.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.changePro = false
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			handleDis() {
				this.changePro = false
			},

			/**
			 *修改用户信息
			 **/
			handleEdit(row) {
				this.dialogTableVisibleEdit = true
				this.editForm = row;
			},
			/**
			 *查看用户信息
			 **/
			handlecheck(row) {
				this.dialogTableVisibleCheck = true
				this.checkForm = row;
			},
			/**
			 *检测记录  pageSize（每页的条数），pageNumber（页码）
			 **/
			handleRecords(row) {
				this.dialogTableVisibleRecords = true
				this.recordsId = row.id
				this.getRecords(row.id)
			},
			getRecords(id) {
				var formData = {
					id: id,
					pageSize: this.pageSize,
					pageNumber: this.pageNumber,
					strat_time: this.table1.where.strat_time?this.table1.where.strat_time.getTime()/1000:'',
					end_time: this.table1.where.end_time?this.table1.where.end_time.getTime()/1000:''

				}
				// this.$http.post('/driver/driver_check_log', formData).then(res => {
				this.$http.post('/store/store_check_log', formData).then(res => {
					// var data = JSON.parse(res.data)
					var data = res.data
					this.recordsTotal = data.total
					if (data.total == 0 || data.total == 'null') {
						this.recordsShow = false
					} else {
						this.recordsShow = true
					}
					if (data.rows.length) {
						this.recordsList = data.rows
					}
				})
			},
			/**
			 *查询
			 **/
			searchRecords() {

					
				this.getRecords(this.recordsId)
			},
			/**
			 *分页
			 **/
			handleCurrentChange(e) {
				//console.log(e)
				this.pageNumber=e
				this.getRecords(this.recordsId)
			},
			/**
			 *查看出车记录
			 **/
			handleCarRecords(row) {
				this.$confirm('确认置为开工?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/range/set_range_status', {
						id: row.id,
						set_status: 1
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
							this.$refs.table1.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})

			},

			/**
			 * 显示编辑
			 */
			edit(row) {
				this.$http.get('/driver/info?id=' + row.id).then(res => {
					if (res.data.code === 0) {
						this.editForm = res.data.data;
						this.showEdit = true;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			/**
			 * 保存编辑
			 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.editForm.pid = this.editForm.pname
						this.editForm.cid = this.editForm.cname
						this.editForm.aid = this.editForm.aname
						this.$http.post('/driver/edit', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
								this.dialogTableVisibleEdit = false;
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/**
			 * 刪除(批量刪除)
			 */
			remove(row) {
				//console.log(row)
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的司机吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/driver/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/driver/delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}
			},
//			restrictedWithdrawal(row){
//				this.$confirm('确定限制该商家提现吗?', '提示', {
//					type: 'warning'
//				}).then(() => {
//					const loading = this.$loading({
//						lock: true
//					});
//						this.$message.error('这里还没有调接口');
//						loading.close();
//				})
//			},
			blackData(row) {

				this.$confirm('确定把该商家拉入黑名单吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/store/editAddBlack', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			forlimit(row) {

				this.$confirm('确定把该商家限号吗?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/store/editRestrict', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})

			},
			/* 导出数据Excel */
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				this.$http
					.get("/driver/exportExcel", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

<style scoped>
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	/deep/.el-dialog {
		margin-top: 60px !important;
	}
</style>
